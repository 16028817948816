.container {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  z-index: 500; /* Specify a stack order in case you're using a different order for other elements */
  cursor: auto; /* Add a pointer on hover */
  overflow: hidden;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.9);

  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.item {
  margin-top: -50px;
  height: 75%;
  @media (max-width: 600px) {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  & > img {
    margin: 0 auto;
    height: 100%;
    @media (max-width: 600px) {
      height: unset;
      width: 100%;
    }
  }
  & > .text {
    font-family: "courier new", monospace;
    letter-spacing: -0.1em;
    line-height: 1.6em;
    font-size: 21px;
    font-weight: 400;
    color: white;
    text-align: center;
    margin-top: 20px;
  }

  & > .close {
    position: absolute;
    top: 35px;
    right: 50px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    @media (max-width: 600px) {
      top: 15px;
      right: 20px;
    }
  }
}
