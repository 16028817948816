.logo {
  position: fixed;
  left: 98px;
  top: 39.5%;
  width: 270px;
  height: 88px;
  z-index: 2;
  @media (max-width: 600px) {
    position: fixed;
    left: 87px;
    top: 171px;
    width: 232px;
    height: 75px;
    z-index: -1;
  }
}
