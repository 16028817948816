.container {
  & > * {
    margin: 0 auto;
    margin-top: 100px;
    @media (max-width: 600px) {
      margin-top: 50px;
    }
  }

  & > :first-child {
    margin-top: 100px;
    margin-bottom: 120px;
  }

  & > :nth-child(2) {
    margin-bottom: 130px;
  }

  & > :nth-child(3) {
    margin-bottom: 120px;
  }

  & > :nth-child(8) {
    margin-top: 80px;
  }

  & > :nth-child(10) {
    margin-top: 60px;
  }

  & > :nth-child(11) {
    margin-top: 50px;
  }

  & > :nth-child(12) {
    margin-top: 60px;
  }

  & > :nth-child(13) {
    margin-top: 80px;
  }

  & > :nth-child(14) {
    margin-top: 60px;
  }

  & > :nth-child(15) {
    margin-top: 80px;
  }

  & > :nth-child(16) {
    margin-top: 50px;
  }

  & > :nth-child(17) {
    margin-top: 100px;
  }

  & > :nth-child(18) {
    margin-top: 50px;
  }

  & > :nth-child(19) {
    margin-top: 70px;
  }

  & > :nth-child(20) {
    margin-top: 50px;
  }

  & > :nth-child(21) {
    margin-top: 60px;
  }

  & > :nth-child(22) {
    margin-top: 150px;
  }

  & > :nth-child(23) {
    margin-top: 50px;
  }

  & > :nth-child(24) {
    margin-top: 50px;
  }

  & > :nth-child(25) {
    margin-top: 70px;
  }

  & > :nth-child(28) {
    margin-top: 150px;
  }

  & > :nth-child(33) {
    margin-top: 150px;
  }

  & > :last-child {
    margin-top: 100px;
    margin-bottom: 150px;
  }
}
