.group {
  width: 645px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 310px 310px;
  row-gap: 25px;
  column-gap: 25px;

  @media (max-width: 600px) {
    width: 280px;
    grid-template-columns: 1fr 1fr;
    row-gap: 7px;
    column-gap: 5px;
  }
}

.group1img1 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 136px;
    height: 177px;
  }
}
.group1img2 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 133px;
    height: 177px;
  }
}
.group1img3 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 177px;
  }
}
.group1img4 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 177px;
  }
}
.group1img5 {
  width: 310px;
  height: 223px;
  @media (max-width: 600px) {
    width: 134px;
    height: 101px;
  }
}
.group1img6 {
  width: 310px;
  height: 223px;
  @media (max-width: 600px) {
    width: 134px;
    height: 101px;
  }
}
.group1img7 {
  width: 310px;
  height: 231px;
  @media (max-width: 600px) {
    width: 134px;
    height: 101px;
  }
}
.group1img8 {
  width: 310px;
  height: 231px;
  @media (max-width: 600px) {
    width: 134px;
    height: 101px;
  }
}
.group1img9 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 135px;
    height: 177px;
  }
}
.group1img10 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 135px;
    height: 177px;
  }
}
.group1img11 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 177px;
  }
}
.group1img12 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 177px;
  }
}
.group1img13 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 183px;
  }
}
.group1img14 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 183px;
  }
}

.group2img1 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 176px;
  }
}
.group2img2 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 176px;
  }
}

.group3img1 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 135px;
    height: 193px;
  }
}
.group3img2 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 135px;
    height: 193px;
  }
}
.group3img3 {
  width: 310px;
  height: 408px;
  @media (max-width: 600px) {
    width: 134px;
    height: 179px;
  }
}
.group3img4 {
  width: 310px;
  height: 403px;
  @media (max-width: 600px) {
    width: 134px;
    height: 179px;
  }
}

.group4img1 {
  width: 310px;
  height: 404px;
  @media (max-width: 600px) {
    width: 138px;
    height: 180px;
  }
}
.group4img2 {
  width: 310px;
  height: 404px;
  @media (max-width: 600px) {
    width: 138px;
    height: 180px;
  }
}
.group4img3 {
  width: 310px;
  height: 404px;
  @media (max-width: 600px) {
    width: 138px;
    height: 180px;
  }
}
.group4img4 {
  width: 310px;
  height: 404px;
  @media (max-width: 600px) {
    width: 138px;
    height: 180px;
  }
}

.group5img1 {
  grid-column: 1 / 4;
  width: 645px;
  height: 483px;
  @media (max-width: 600px) {
    width: 280px;
    height: 211px;
  }
}
.group5img2 {
  width: 310px;
  height: 405px;
  @media (max-width: 600px) {
    width: 134px;
    height: 178px;
  }
}
.group5img3 {
  width: 310px;
  height: 405px;
  @media (max-width: 600px) {
    width: 134px;
    height: 178px;
  }
}

.group6img1 {
  grid-column: 1 / 4;
  width: 645px;
  height: 366px;
  @media (max-width: 600px) {
    width: 280px;
    height: 158px;
  }
}
.group6img2 {
  grid-column: 1 / 4;
  width: 645px;
  height: 1131px;
  @media (max-width: 600px) {
    width: 279px;
    height: 499px;
  }
}

.group7img1 {
  grid-column: 1 / 4;
  width: 645px;
  height: 369px;
  @media (max-width: 600px) {
    width: 280px;
    height: 161px;
  }
}
.group7img2 {
  grid-column: 1 / 4;
  width: 645px;
  height: 364px;
  @media (max-width: 600px) {
    width: 280px;
    height: 159px;
  }
}
.group7img3 {
  grid-column: 1 / 4;
  width: 645px;
  height: 358px;
  @media (max-width: 600px) {
    width: 280px;
    height: 157px;
  }
}

.group8img1 {
  grid-column: 1 / 4;
  width: 645px;
  height: 426px;
  @media (max-width: 600px) {
    width: 282px;
    height: 189px;
  }
}
.group8img2 {
  grid-column: 1 / 4;
  width: 645px;
  height: 426px;
  @media (max-width: 600px) {
    width: 283px;
    height: 196px;
  }
}
.group8img3 {
  grid-column: 1 / 4;
  width: 645px;
  height: 426px;
  @media (max-width: 600px) {
    width: 284px;
    height: 191px;
  }
}
