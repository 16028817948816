.group1 {
  margin: 0 auto;
  margin-top: 100px;
  width: 643px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  @media (max-width: 600px) {
    width: 280px;
  }
}

.group1item1 {
  width: 643px;
  @media (max-width: 600px) {
    width: 280px;
  }
}

.group1item2 {
  width: 643px;
  @media (max-width: 600px) {
    width: 280px;
  }
}

.group1item3 {
  width: 643px;
  @media (max-width: 600px) {
    width: 280px;
  }
}

.group2 {
  margin: 0 auto;
  margin-top: 100px;
  width: 643px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  @media (max-width: 600px) {
    width: 280px;
  }
}

.group2item1 {
  width: 643px;
  @media (max-width: 600px) {
    width: 280px;
  }
}

.group2item2 {
  width: 643px;
  @media (max-width: 600px) {
    width: 280px;
  }
}
