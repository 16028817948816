.map1 {
  position: relative;
  width: 600px;
  height: 809px;
  @media (max-width: 600px) {
    width: 280px;
    height: 378px;
  }
}

.map2 {
  position: relative;
  width: 834px;
  height: 626px;
  @media (max-width: 600px) {
    width: 280px;
    height: 210px;
  }
}

.thumbnail {
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

// kat start
.img7 {
  width: 26px;
  height: 17px;
  top: 116px;
  left: 287px;
  @media (max-width: 600px) {
    width: 30px;
    height: 20px;
    top: 42px;
    left: 125px;
  }
}

.img8 {
  width: 26px;
  height: 17px;
  top: 116px;
  left: 370px;
  @media (max-width: 600px) {
    width: 30px;
    height: 20px;
    top: 42px;
    left: 161px;
  }
}

.img26 {
  width: 26px;
  height: 17px;
  top: 138px;
  left: 370px;
  @media (max-width: 600px) {
    width: 16px;
    height: 10px;
    top: 65px;
    left: 167px;
  }
}

.img10 {
  width: 27px;
  height: 17px;
  top: 138px;
  left: 423px;
  @media (max-width: 600px) {
    width: 16px;
    height: 10px;
    top: 65px;
    left: 198px;
  }
}

.img9 {
  width: 21px;
  height: 30px;
  top: 136px;
  left: 184px;
  @media (max-width: 600px) {
    width: 20px;
    height: 28px;
    top: 62px;
    left: 79px;
  }
}

.img2 {
  width: 26px;
  height: 17px;
  top: 166px;
  left: 331px;
  @media (max-width: 600px) {
    width: 30px;
    height: 20px;
    top: 90px;
    left: 134px;
  }
}

.img3 {
  width: 21px;
  height: 30px;
  top: 152px;
  left: 41px;
  @media (max-width: 600px) {
    width: 20px;
    height: 28px;
    top: 62px;
    left: 20px;
  }
}

.img5 {
  width: 22px;
  height: 30px;
  top: 240px;
  left: 41px;
  @media (max-width: 600px) {
    width: 20px;
    height: 27px;
    top: 101px;
    left: 20px;
  }
}

.img4 {
  width: 26px;
  height: 17px;
  top: 321px;
  left: 41px;
  @media (max-width: 600px) {
    width: 30px;
    height: 19px;
    top: 154px;
    left: 20px;
  }
}

.img12 {
  width: 21px;
  height: 30px;
  top: 450px;
  left: 102px;
  @media (max-width: 600px) {
    width: 14px;
    height: 20px;
    top: 208px;
    left: 40px;
  }
}

.img6 {
  width: 26px;
  height: 17px;
  top: 223px;
  left: 357px;
  @media (max-width: 600px) {
    width: 30px;
    height: 19px;
    top: 101px;
    left: 168px;
  }
}

.img11 {
  width: 26px;
  height: 17px;
  top: 223px;
  left: 532px;
  @media (max-width: 600px) {
    width: 30px;
    height: 20px;
    top: 90px;
    left: 232px;
  }
}

.img1 {
  width: 27px;
  height: 17px;
  top: 338px;
  left: 330px;
  @media (max-width: 600px) {
    width: 30px;
    height: 19px;
    top: 154px;
    left: 136px;
  }
}

.img27 {
  width: 22px;
  height: 30px;
  top: 462px;
  left: 346px;
  @media (max-width: 600px) {
    width: 22px;
    height: 26px;
    top: 218px;
    left: 153px;
  }
}

.img28 {
  width: 21px;
  height: 30px;
  top: 745px;
  left: 54px;
  @media (max-width: 600px) {
    width: 20px;
    height: 28px;
    top: 333px;
    left: 24px;
  }
}
// kat end

// prizemlje start
.img25 {
  width: 33px;
  height: 22px;
  top: 19px;
  left: 143px;
  @media (max-width: 600px) {
    width: 30px;
    height: 20px;
    top: 0px;
    left: 49px;
  }
}

.img17 {
  width: 23px;
  height: 30px;
  top: 87px;
  left: 500px;
  @media (max-width: 600px) {
    width: 20px;
    height: 27px;
    top: 9px;
    left: 167px;
  }
}

.img18 {
  width: 37px;
  height: 24px;
  top: 130px;
  left: 500px;
  @media (max-width: 600px) {
    width: 17px;
    height: 11px;
    top: 46px;
    left: 167px;
  }
}

.img19 {
  width: 23px;
  height: 30px;
  top: 106px;
  left: 629px;
  @media (max-width: 600px) {
    width: 20px;
    height: 26px;
    top: 20px;
    left: 215px;
  }
}

.img20 {
  width: 34px;
  height: 22px;
  top: 171px;
  left: 756px;
  @media (max-width: 600px) {
    width: 17px;
    height: 11px;
    top: 51px;
    left: 247px;
  }
}

.img16 {
  width: 21px;
  height: 30px;
  top: 193px;
  left: 369px;
  @media (max-width: 600px) {
    width: 14px;
    height: 19px;
    top: 64px;
    left: 117px;
  }
}

.img13 {
  width: 37px;
  height: 24px;
  top: 364px;
  left: 25px;
  @media (max-width: 600px) {
    width: 40px;
    height: 26px;
    top: 117px;
    left: 8px;
  }
}

.img21 {
  width: 21px;
  height: 30px;
  top: 289px;
  left: 790px;
  @media (max-width: 600px) {
    width: 20px;
    height: 28px;
    top: 78px;
    left: 250px;
  }
}

.img22 {
  width: 36px;
  height: 24px;
  top: 408px;
  left: 775px;
  @media (max-width: 600px) {
    width: 15px;
    height: 11px;
    top: 150px;
    left: 255px;
  }
}

.img30 {
  width: 31px;
  height: 19px;
  top: 553px;
  left: 780px;
  @media (max-width: 600px) {
    width: 17px;
    height: 11px;
    top: 183px;
    left: 253px;
  }
}

.img23 {
  width: 22px;
  height: 30px;
  top: 572px;
  left: 661px;
  @media (max-width: 600px) {
    width: 20px;
    height: 27px;
    top: 175px;
    left: 209px;
  }
}

.img24 {
  width: 37px;
  height: 24px;
  top: 578px;
  left: 517px;
  @media (max-width: 600px) {
    width: 17px;
    height: 11px;
    top: 190px;
    left: 167px;
  }
}

.img15 {
  width: 23px;
  height: 30px;
  top: 437px;
  left: 336px;
  @media (max-width: 600px) {
    width: 16px;
    height: 19px;
    top: 127px;
    left: 104px;
  }
}

.img14 {
  width: 35px;
  height: 22px;
  top: 495px;
  left: 287px;
  @media (max-width: 600px) {
    width: 17px;
    height: 11px;
    top: 164px;
    left: 95px;
  }
}
// prizemlje end
