.img1 {
  width: 636px;
  height: 422px;
  @media (max-width: 600px) {
    width: 280px;
    height: 187px;
  }
}

.img2 {
  width: 635px;
  height: 841px;
  @media (max-width: 600px) {
    width: 280px;
    height: 371px;
  }
}

.img3 {
  width: 635px;
  height: 355px;
  @media (max-width: 600px) {
    width: 280px;
    height: 157px;
  }
}

.img4 {
  width: 637px;
  height: 422px;
  @media (max-width: 600px) {
    width: 280px;
    height: 185px;
  }
}

.img5 {
  width: 643px;
  height: 959px;
  @media (max-width: 600px) {
    width: 280px;
    height: 417px;
  }
}

.img6 {
  width: 636px;
  height: 358px;
  @media (max-width: 600px) {
    width: 280px;
    height: 158px;
  }
}

.img7 {
  width: 636px;
  height: 945px;
  @media (max-width: 600px) {
    width: 280px;
    height: 416px;
  }
}
