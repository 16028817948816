.cro {
  color: #4e6636;
}

.eng {
  color: #804808;
}

.item1,
.item2,
.item3,
.item4,
.item5,
.item6,
.item7,
.item8,
.item9,
.item10,
.item11,
.item12,
.item13,
.item14 {
  font-size: 14px;
  font-family: "courier new", monospace;
  letter-spacing: -0.1em;
  line-height: 1.7em;
  font-weight: 400;

  width: 840px;
  margin: 0 auto;
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  column-gap: 65px;
  text-align: justify;
  & > ul {
    margin: 0px;
    padding-left: 1.3em;
    & > li {
      margin-top: 20px;
    }
  }

  @media (max-width: 600px) {
    width: 280px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 60px;
    & > ul {
      & > li {
        margin-top: 15px;
      }
    }
  }
}

.item1 {
  width: 805px;
  column-gap: 130px;
  @media (max-width: 600px) {
    width: 280px;
    row-gap: 75px;
  }
}

.item3,
.item4,
.item5,
.item6,
.item7,
.item8,
.item9,
.item10,
.item11,
.item12,
.item13 {
  @media (max-width: 600px) {
    row-gap: 10px;
  }
}

.item6 {
  & > ul {
    & > li {
      margin-top: 0px;
    }
  }
}

.item14 {
  font-size: 12px;
}
